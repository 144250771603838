import React, { useState, useEffect } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { makeRequest } from '../../api';
import { Options } from "../../interfaces/Options";
import { Match } from "../../interfaces/Match";
import { HalfTimeRow } from "./HalfTimeRow";

const HalfTimeTable = () => {
    const [ data, setData ] = useState<Match[]>([]);

    const fetchData = async () => {
        const options: Options = {
            method: 'get',
        }
        const response: any = await makeRequest('api/get_sh_potential_matches', options);
        if (response.status === 200) {
            setData(response.data)
        }
    };

    const handleFetchData = () => {
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <h1>Partidos Terminados 45:00-47:00</h1>
            <Button 
                variant="contained" 
                color="primary"
                onClick={handleFetchData}
            >
            Refresh data
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Liga</TableCell>
                            <TableCell>Partido</TableCell>
                            <TableCell>Fin PT</TableCell>
                            <TableCell>Timer</TableCell>
                            <TableCell>Tot Goles</TableCell>
                            <TableCell>Diff Goles</TableCell>
                            <TableCell>Under</TableCell>
                            <TableCell>Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((match: Match) => (
                            <HalfTimeRow match={match} />                            
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export { HalfTimeTable }